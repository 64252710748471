import React from 'react';
import styled from 'styled-components';

const SquareStyle = styled.div``;

interface IIcon {
  className?: string;
  style?: any;
}
export const Icon: React.FC<IIcon> = ({ style, className }) => {
  return (
    <SquareStyle className={className} style={style}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="12.2446"
          y="1.43374"
          width="15.2894"
          height="15.2894"
          transform="rotate(45 12.2446 1.43374)"
          stroke="#FC3A3A"
          strokeWidth="2"
        />
      </svg>
    </SquareStyle>
  );
};
