import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Icon } from './ui/Icon';
import { RainbowLine } from './ui/RainbowLine';

const WhyPrivatMegleren: React.FC = () => {
  return (
    <WhyPrivatMeglerenStyle>
      <RainbowLine className="line" />
      <Heading className="meglercvtitle" tag="h2">
        Hvorfor PrivatMegleren
      </Heading>
      <Paragraph className="whyprivatmeglerentext" center>
        PrivatMegleren leverer en annerledes og skreddersydd meglertjeneste,
        designet for enhver eiendom, med sylskarp og treffende markedsføring. Vi
        fremhever boligens unike kvaliteter, for å oppnå de beste resultatene.
        Vår oppgave er å oppnå resultater for deg når du er i en situasjon der
        mye står på spill. Om du skal kjøpe eller selge, hjelper vi deg med
        strategi, taktisk planlegging og gjennomføring av det som skal gjøres.
        Med bransjeledende produkter og innovative løsninger gir vi deg et
        fortrinn i eiendomssalget. Vårt mål er å gi enhver kunde en opplevelse
        utover det som forventes, og å oppfylle kjøperens ønsker til et nytt
        hjem. Hele 9 av 10 kunder velger oss på anbefaling fra tidligere kunder.
        Vi håper og tror dette er en bekreftelse på at våre meglere leverer en
        opplevelse og resultater som overgår de fleste forventninger til en
        eiendomsmegler.
      </Paragraph>
      <Paragraph className="whyprivatmeglerentext" center>
        Vi ønsker at våre kunder skal ha høye forventninger til oss.. Vi håper
        og tror dette er en bekreftelse på at våre meglere leverer en opplevelse
        og resultater som overgår de fleste forventninger til en eiendomsmegler.
      </Paragraph>
      <Paragraph className="whyprivatmeglerentext" center>
        Vurderer du å selge eller kjøpe eiendom, hører vi gjerne fra deg.
      </Paragraph>
      <div className="links">
        <a
          className="link"
          target="_blank"
          href="https://privatmegleren.no/nybygg/"
        >
          Privatmegleren Nyeboliger
        </a>
        <br />
        <a
          className="link"
          target="_blank"
          href="https://privatmegleren.no/fritid"
        >
          Privatmegleren Fritid
        </a>
        <br />
        <a
          className="link"
          target="_blank"
          href="https://privatmegleren.no/kjop"
        >
          Privatmegleren Kjøp
        </a>
        <br />
        <a
          className="link"
          target="_blank"
          href="https://privatmegleren.no/boligbytte"
        >
          Privatmegleren Boligbytte
        </a>
        <br />
        <a
          className="link"
          target="_blank"
          href="https://privatmegleren.no/kampanje/kvadrat"
        >
          Privatmegleren Kvadrat
        </a>
        <Icon style={{ paddingTop: '1.5em' }} />
      </div>
      <Heading style={{ textAlign: 'center' }} tag="h3">
        Skreddersydd annonsering med kunstig intelligens
      </Heading>
      <Paragraph className="whyprivatmeglerentext" center>
        Med vår teknologi viser undersøkelser at vi kan få opptil 68 % flere på
        visning. Hele 30 % av de som har kjøpt ny eiendom oppgir at de ikke var
        på aktiv leting. Det betyr at en stor andel av potensielle kjøpere ikke
        er aktive i tradisjonelle mediekanaler. Med Presisjon klarer vi likevel
        å nå denne attraktive målgruppen, og vekker deres nysgjerrighet med
        skreddersydde annonser. Kort fortalt gjør kombinasjonen av Presisjon og
        tradisjonell annonsering at du treffer flere potensielle kjøpere. Det
        kan bety mye for solgtprisen.
      </Paragraph>
      <DivLink className="links">
        <a
          target="_blank"
          href="https://privatmegleren.no/kampanje/presisjon"
          className="klikk"
        >
          Les mer
        </a>
      </DivLink>
      <RainbowLine className="line" />
    </WhyPrivatMeglerenStyle>
  );
};

const DivLink = styled.div`
  padding-bottom: 2em;
`;

const WhyPrivatMeglerenStyle = styled.div`
  padding: 0 2em;
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
  .links {
    text-align: center;
  }

  .meglercvtitle {
    text-align: center;
  }

  .whyprivatmeglerentext {
    padding: 0 2rem;
  }

  .line {
    display: none;
  }

  /* Desktop */
  @media all and (min-width: 600px) {
    padding: 3em 0 3em;
    .whyprivatmeglerentext {
      width: 100%;
      max-width: 56%;
      padding-bottom: 2em;
      margin: 0 auto;
    }
    .meglercvtitle {
      padding-top: 1em;
      margin-top: 0;
    }
  }

  @media all and (min-width: 1000px) {
    .line {
      display: block;
      width: 67%;
    }
  }
  @media all and (min-width: 1400px) {
    .line {
      display: block;
      width: 57%;
    }
  }
`;

export default WhyPrivatMegleren;
