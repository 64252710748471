import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { MeglerReferencesList } from './MeglerReferencesList';
import { McvFeedbackItem } from '../../../../__types__/generated/cluster';

interface IMeglerReferences {
  showDesktopRef?: boolean;
  showMobileRef?: boolean;
  className?: string;
  style?: any;
  items?: Partial<McvFeedbackItem>[] | null;
}
const MeglerReferences: React.FC<IMeglerReferences> = ({
  showDesktopRef,
  showMobileRef,
  className,
  style,
  items
}) => {
  if (!items?.length) {
    return null;
  }

  return (
    <>
      <MeglerReferencesStyle style={style} className={className}>
        <Heading className="referencestitle" tag="h3">
          Hva sies om meg?
        </Heading>
        {showDesktopRef ? (
          <MeglerReferencesList
            className="referencesdesktop"
            items={items}
            itemsPrPage={3}
          />
        ) : null}
        {showMobileRef ? (
          <MeglerReferencesList
            className="referencesmobile"
            items={items}
            itemsPrPage={1}
          />
        ) : null}
      </MeglerReferencesStyle>
    </>
  );
};

const MeglerReferencesStyle = styled.div`
  .referencestitle {
    text-align: center;
    padding-top: 1em;
  }
  .referencesdesktop {
    display: none;
  }
  .referencesmobile {
    display: block;
  }
  /* Desktop */
  @media all and (min-width: 1100px) {
    .referencestitle {
      text-align: center;
      padding-top: 1em;
    }
    .referencesdesktop {
      display: block;
    }
    .referencesmobile {
      display: none;
    }
  }
`;

export default MeglerReferences;
