import * as React from 'react';
import styled from 'styled-components';
import { IType } from '../Carousels/BrokerItem';

interface IMeglerImage {
  type?: IType | null | string;
  videoId?: string | null;
  className?: string;
}

export const Video: React.FC<IMeglerImage> = ({ type, videoId, className }) => {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const handleOverlayClick = () => {
    setShowOverlay(true);
  };

  if (!type || !videoId) return null;
  if (type === IType.Youtube) {
    return (
      <VideoContainer className={className}>
        <iframe
          title="YouTube video player"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </VideoContainer>
    );
  }

  if (type === IType.Vimeo) {
    return (
      <EmbedContainer>
        {showOverlay ? (
          <>
            <iframe
              title="Vimeo video player"
              className="VimeoOuterContainer"
              src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
              frameBorder="0"
              width="100%"
              height="100%"
              allow="autoplay;"
            ></iframe>
          </>
        ) : (
          <>
            <VideoOverlayWithPlayButton onClick={handleOverlayClick} />
          </>
        )}
      </EmbedContainer>
    );
  }

  return null;
};

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    margin: 0px;
  }
`;
const EmbedContainer = styled.div`
  position: relative;
  /*   padding: 38.07% 0 0 0; */
  padding: 65.07% 0 0 0;
  margin: 1em 0 1em;
  .VimeoOuterContainer {
    margin-top: 1em;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 375px) {
    margin-top: 1em;
    padding: 73.07% 0 0 0;
  }
`;
interface IVideoOverlayWithPlayButton {
  onClick?: () => void;
}

const VideoOverlayWithPlayButton: React.FC<IVideoOverlayWithPlayButton> = ({
  onClick
}) => {
  return (
    <VideoOverlayWithPlayButtonStyle onClick={onClick}>
      <button className="playbutton" />
    </VideoOverlayWithPlayButtonStyle>
  );
};

const VideoOverlayWithPlayButtonStyle = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  z-index: 1;
  isolation: isolate;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  .playbutton {
    width: 6em;
    height: 6em;
    border-radius: 50%;
    border: none;

    background: url(https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/play.svg)
      center center / contain no-repeat;
    cursor: pointer;
  }
  @media all and (min-width: 600px) {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    z-index: 1;
    isolation: isolate;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: #222224;
    .playbutton {
      width: 6em;
      height: 6em;
      border-radius: 50%;
      border: none;

      background: url(https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/play.svg)
        center center / contain no-repeat;
      cursor: pointer;
    }
  }
`;

export default VideoOverlayWithPlayButton;
