import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring, useSprings, useTrail } from 'react-spring';

import { Download } from '../../../ui/icons/Download';
import { Share } from '../../../ui/icons/Share';
import { EmailIcon } from '../../../ui/icons/Email';
import { FacebookIcon } from '../../../ui/icons/Facebook';
import { TwitterIcon } from '../../../ui/icons/Twitter';
import { LinkedInIcon } from '../../../ui/icons/LinkedIn';
import { GooglePlusIcon } from '../../../ui/icons/GooglePlus';

type VCardShareType = 'share' | 'download';

interface IVCartItem {
  type: VCardShareType;
  href?: string;
  onClick?: () => void;
  showTitles: boolean;
}

const VCardItem: React.FC<IVCartItem> = ({
  type,
  href,
  onClick,
  showTitles
}) => {
  if (type === 'download') {
    return (
      <StyledSaveVCard as="a" href={href}>
        <Download className="icon-download" />
        {showTitles ? <span>Lagre visittkort</span> : null}
      </StyledSaveVCard>
    );
  } else if (type === 'share') {
    return (
      <StyledSaveVCard onClick={onClick}>
        <Share className="icon-share" />
        {showTitles ? <span>Del visittkort</span> : null}
      </StyledSaveVCard>
    );
  }
  return null;
};

interface ISharingVcard {
  name: string;
  title: string;
  path: string;
  className?: string;
  showTitles: boolean;
  showCards?: VCardShareType[];
}

export const SharingVcard: React.FC<ISharingVcard> = ({
  name,
  title,
  path,
  className,
  showTitles,
  showCards
}) => {
  const [showSocial, setShowSocial] = React.useState(false);
  const animationProps = useSpring({
    from: { height: 0 },
    to: { height: showSocial ? 60 : 0 }
  });

  const items = [
    {
      type: 'email',
      link: `mailto:?subject=${name}, ${title} - PrivatMegleren&body=https://privatmegleren.no/megler/${path}`
    },
    {
      type: 'fb',
      link: `https://www.facebook.com/sharer/sharer.php?u=https://privatmegleren.no/megler/${path}`
    },
    {
      type: 'twitter',
      link: `https://twitter.com/home?status=https://privatmegleren.no/megler/${path}`
    },
    {
      type: 'linkedin',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=https://privatmegleren.no/megler/${path}`
    }
  ];

  const [springs, set]: any = useTrail(items.length, () => ({
    opacity: 0,
    transform: 'translate3d(0, 20px, 0)'
  }));

  React.useEffect(() => {
    if (showSocial === true) {
      set({ delay: 500, opacity: 1, transform: 'translate3d(0, 0, 0)' });
    } else if (showSocial === false) {
      set({ opacity: 0, transform: 'translate3d(0, 20px, 0)' });
    }
  }, [showSocial]);

  const show = (type: VCardShareType) => {
    return !showCards?.length || showCards?.includes(type);
  };

  return (
    <SharingWrapper>
      {show('share') ? (
        <SharingOnSocialMedia style={animationProps} className={className}>
          {springs.map((props: any, index: number) => {
            return (
              <SharingButton
                key={`social_${index}`}
                style={props}
                href={items[index].link}
                target="_blank"
              >
                {items[index].type === 'email' ? (
                  <EmailIcon className="icon-email" />
                ) : null}
                {items[index].type === 'fb' ? (
                  <FacebookIcon className="icon-facebook" />
                ) : null}
                {items[index].type === 'twitter' ? (
                  <TwitterIcon className="icon-twitter" />
                ) : null}
                {items[index].type === 'linkedin' ? (
                  <LinkedInIcon className="icon-linkedin" />
                ) : null}
              </SharingButton>
            );
          })}
        </SharingOnSocialMedia>
      ) : null}
      <SharingBottomWrapper className={className}>
        {show('download') ? (
          <VCardItem
            showTitles={showTitles}
            type="download"
            href={`/megler/${path}/vcard`}
          />
        ) : null}
        {show('share') ? (
          <VCardItem
            showTitles={showTitles}
            type="share"
            onClick={() => setShowSocial(!showSocial)}
          />
        ) : null}
      </SharingBottomWrapper>
    </SharingWrapper>
  );
};

const SharingButton = styled(animated.a)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 0;
  outline: none;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  .icon-email {
    width: auto;
    svg {
      width: 30px;
      height: 35px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .icon-facebook {
    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .icon-twitter {
    svg {
      width: 35px;
      height: 30px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .icon-linkedin {
    svg {
      width: 35px;
      height: 30px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .icon-googleplus {
    svg {
      width: 35px;
      height: 30px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const SharingOnSocialMedia = styled(animated.div)`
  position: relative;
  top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
`;

const SharingWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
`;

const SharingBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  /* responsive  */
`;

const StyledSaveVCard = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }

  span {
    margin-left: 15px;
    color: white;
  }

  .icon-download {
    svg {
      width: 22px;
      height: 22px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .icon-share {
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
