import React from 'react';
import styled from 'styled-components';

interface IRainbowLine {
  className?: string;
  width?: number;
}

const StyledRainbowLine = styled.div<IRainbowLine>`
  width: ${(props) => (props.width ? props.width : ' 54%')};
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    #c17e54 15%,
    #f1c087 25%,
    #c17e54 50%,
    #b97149 75%,
    #f1c087 100%
  );
  height: 1px;
`;

export const RainbowLine: React.FC<IRainbowLine> = ({ className, width }) => {
  return (
    <StyledRainbowLine width={width} className={className}></StyledRainbowLine>
  );
};
