import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { McvFrontendContentItem } from '../../../../__types__/generated/cluster';

interface IItem {
  className?: string;
  title?: string | null;
  content?: Partial<McvFrontendContentItem>[] | null;
}

export const MeglerTeamItem: React.FC<IItem> = ({
  className,
  title,
  content
}) => {
  return (
    <>
      {title ? <Header tag="h2">{title}</Header> : null}
      <Container>
        {content?.map((obj, index: number) => {
          return (
            <ContentRow key={`cvcontent${index}`} center>
              {obj?.value}
            </ContentRow>
          );
        })}
      </Container>
    </>
  );
};

const Header = styled(Heading)`
  text-align: center;
  //padding-top: 2em;
  margin-top: 0;
  @media all and (min-width: 1100px) {
    //margin: 0;
  }
`;

const Container = styled.div`
  padding: 1em 0 2em;
  width: 100%;
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContentRow = styled(Paragraph)`
  width: 100%;
  margin: 0 auto;
`;
