import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '../ui/Icon';
import MeglerExperienceAndEducation from './MeglerExperienceAndEducation';
import MeglerJob from '../MeglerJob';
import {
  McvExperienceItem,
  McvExperienceType
} from '../../../../__types__/generated/cluster';

const ImageBox = styled.div`
  img {
    margin: 0 auto;
    display: block;
  }
`;

interface IMeglerExperienceAndEducationWithContent
  extends React.ComponentProps<'div'> {
  className?: string;
  title?: string;
  experience?: Partial<McvExperienceItem>[];
  useFiller?: boolean;
}

export const MeglerExperienceAndEducationWithContent: React.FC<
  IMeglerExperienceAndEducationWithContent
> = ({ className, title, experience, useFiller }) => {
  const [showDivider, SetShowDivider] = React.useState(
    !experience?.length
      ? false
      : experience?.findIndex((e) => e?.type === McvExperienceType.Work) > -1 &&
          experience?.findIndex(
            (e) => e?.type === McvExperienceType.Education
          ) > -1
  );

  if (!experience?.length) {
    return !useFiller ? null : (
      <ImageBox className={className ? className : ''}>
        <img
          src="https://cdn.reeltime.no/pm_assets/meglercv/engodstart.jpg"
          alt="En god start"
          title="En god start"
        />
      </ImageBox>
    );
  }

  return (
    <MeglerExperienceAndEducation
      title={title}
      className={className ? className : ''}
      style={{ textAlign: 'center' }}
    >
      {experience
        ?.filter((obj) => {
          return (
            obj?.fromYear && obj?.type === McvExperienceType.Work && obj?.text
          );
        })
        ?.sort((a, b) => {
          return YearCompare(a.fromYear ?? 0, b.fromYear ?? 0);
        })
        ?.map((obj, index: number) => {
          return (
            <MeglerJob
              key={`efaring${index}`}
              year={
                obj?.toYear
                  ? `${obj.fromYear}-${obj.toYear}`
                  : `Fra ${obj.fromYear}`
              }
              location={obj?.location ? obj?.location : ``}
              description={obj.text}
            />
          );
        })}
      {showDivider ? (
        <>
          <Icon style={{ padding: '2em 0' }} />
        </>
      ) : null}
      {experience
        ?.filter((obj) => {
          return (
            obj?.fromYear &&
            obj?.type === McvExperienceType.Education &&
            obj?.text
          );
        })
        ?.sort((a, b) => {
          return YearCompare(a.fromYear ?? 0, b.fromYear ?? 0);
        })
        ?.map((obj, index: number) => {
          return (
            <MeglerJob
              key={`efaring${index}`}
              year={
                obj?.toYear
                  ? `${obj.fromYear}-${obj.toYear}`
                  : `Fra ${obj.fromYear}`
              }
              location={obj?.location ? obj?.location : ``}
              description={obj.text}
            />
          );
        })}
    </MeglerExperienceAndEducation>
  );
};

const YearCompare = (a: number, b: number) => {
  if (a < b) return 1;
  if (a > b) return -1;
  return 0;
};
