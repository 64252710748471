import React from 'react';
import styled from 'styled-components';

export enum IType {
  Image = 'image',
  Youtube = 'youtube',
  Vimeo = 'vimeo'
}

export interface IItem {
  type: IType;
  url: string;
  text?: string;
}

export const BrokerItem: React.FC<IItem> = ({ type, url, text }) => {
  if (!(type && url)) return null;

  if (type === IType.Image) {
    return (
      <>
        <Wrapper>
          <img className="brokerimages" src={url} alt="bilde" />
        </Wrapper>
        {text ? <p>{text}</p> : null}
      </>
    );
  }

  if (type === IType.Youtube) {
    return (
      <Wrapper>
        <VideoContainer>
          <iframe
            title="YouTube video player"
            src={`https://www.youtube.com/embed/${url}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </VideoContainer>
      </Wrapper>
    );
  }

  if (type === IType.Vimeo) {
    return (
      <Wrapper>
        <VideoContainer className="vimeo">
          <iframe
            title="Vimeo video player"
            src={`https://player.vimeo.com/video/${url}`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoContainer>
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled.div`
  position: relative;
  display: block;
  .brokerimages {
    width: 100%;
  }
  /* Desktop */
  @media all and (max-width: 700px) {
    .brokerimages {
      width: 100%;
      /*    max-width: 600px; */
    }
  }
`;

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    margin: 0px;
  }
`;
