import * as React from 'react';
import styled from 'styled-components';

interface IShare {
  className: string;
}

export const Share: React.FC<IShare> = ({ className }) => {
  return (
    <StyledShare className={className}>
      <svg
        width="26"
        height="38"
        viewBox="0 0 26 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8485 1.15148L13 0.302948L12.1515 1.15148L2.15147 11.1515L3.84853 12.8485L11.8 4.89706V16H2.4H0V18.4V32.5V35.6V38H2.4H23.6H26V35.6V18.4V16H23.6H14.2V4.89706L22.1515 12.8485L23.8485 11.1515L13.8485 1.15148ZM11.8 18.4V29H14.2V18.4H23.6V35.6H2.4V32.5V18.4H11.8Z"
          fill="black"
        />
      </svg>
    </StyledShare>
  );
};

const StyledShare: any = styled.div``;
