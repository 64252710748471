import * as React from 'react';
import styled from 'styled-components';

interface IMeglerImage {
  url: string;
  className?: string;
}
export const MeglerImage: React.FC<IMeglerImage> = ({ url, className }) => {
  return (
    <MeglerImageStyle className={className}>
      <img className="megler" src={url} alt="" />
    </MeglerImageStyle>
  );
};

const MeglerImageStyle = styled.div`
  .megler {
    width: 100%;
  }

  @media all and (min-width: 1400px) {
    .megler {
      object-fit: contain;
      width: 100%;
    }
  }
`;
