import { gql } from '@apollo/client';

export const EMPLOYEE_QUERY = gql`
  query Employee($input: EmployeeInput!, $size: EmployeeImageSize) {
    employee(input: $input) {
      employeeAlias
      title
      email
      workPhone
      mobilePhone
      name
      role
      about
      image {
        url(size: $size)
      }
      texts {
        ... on EmployeeTextsDefault {
          about
        }
      }
      departments {
        name
        brandId
        alias
        tags
      }
      meglercv
    }
  }
`;
