import * as React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Icon } from '../ui/Icon';

const MeglerReferenceStyle = styled.div`
  @media all and (min-width: 600px) {
    .referenceperson {
      padding-top: 1em;
    }
  }
`;

interface IMeglerReference {
  text: string;
  person?: string;
  className?: string;
}
export const MeglerReferenceItem: React.FC<IMeglerReference> = ({
  text,
  person,
  className
}) => {
  return (
    <MeglerReferenceStyle className={className}>
      <Icon style={{ paddingTop: '18px', textAlign: 'center' }} />
      <Paragraph center>{text}</Paragraph>
      <Paragraph
        className="referenceperson"
        style={{ fontWeight: 'bold' }}
        center
      >
        {person}
      </Paragraph>
    </MeglerReferenceStyle>
  );
};
