import * as React from 'react';
import styled from 'styled-components';

interface IFacebook {
  className?: string;
}

export const FacebookIcon: React.FC<IFacebook> = ({ className }) => {
  return (
    <StyledIcon className={className}>
      <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.4375 0 H 2.8125 C 2.06658 0 1.35121 0.296316 0.823762 0.823762 C 0.296316 1.35121 0 2.06658 0 2.8125 L 0 23.4375 C 0 24.1834 0.296316 24.8988 0.823762 25.4262 C 1.35121 25.9537 2.06658 26.25 2.8125 26.25 H 10.8545 V 17.3256 H 7.16309 V 13.125 H 10.8545 V 9.92344 C 10.8545 6.28184 13.0225 4.27031 16.343 4.27031 C 17.9332 4.27031 19.5961 4.55391 19.5961 4.55391 V 8.12813 H 17.7639 C 15.9586 8.12813 15.3955 9.24844 15.3955 10.3975 V 13.125 H 19.4256 L 18.7811 17.3256 H 15.3955 V 26.25 H 23.4375 C 24.1834 26.25 24.8988 25.9537 25.4262 25.4262 C 25.9537 24.8988 26.25 24.1834 26.25 23.4375 V 2.8125 C 26.25 2.06658 25.9537 1.35121 25.4262 0.823762 C 24.8988 0.296316 24.1834 0 23.4375 0 Z"
          fill="black"
        />
      </svg>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  svg {
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;
