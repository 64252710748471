import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { RainbowLine } from '../ui/RainbowLine';

interface IQuote {
  style: any;
  text: string;
  author?: string;
}
const MeglerQuote: React.FC<IQuote> = ({ style, text, author }) => {
  return (
    <QuoteStyle style={style}>
      <RainbowLine className="line" />
      <Heading className="quote" tag="h2">
        {text}
      </Heading>
      <p className="author">{author}</p>
      <RainbowLine className="line" />
    </QuoteStyle>
  );
};

const QuoteStyle = styled.div`
  width: 100%;
  padding: 7em 0 30px 0;
  .line {
    width: 71%;
  }
  .quote {
    padding: 0 1em;
  }
  /* Desktop */
  @media all and (min-width: 600px) {
    .line {
      width: 75%;
    }
    .quote {
      padding: 1.5rem 5rem;
      padding-bottom: 0;
      max-width: 1000px;
      margin: 0 auto;
    }
    .author {
      padding-bottom: 1em;
    }
  }

  @media all and (min-width: 1300px) {
    padding: 12em 0 30px 0;
    .top {
      margin-top: 6em;
    }
    .quote {
      max-width: 1000px;
      margin: 0 auto;
    }
    .line {
      width: 53%;
    }
  }
`;

export default MeglerQuote;
