import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';

const ExperienceAndEducationStyle = styled.div`
  background-color: #2e2d2e;
  padding: 3em 0 3em;
  margin-top: 3em;
  /* Desktop */
  @media all and (min-width: 1100px) {
    margin-top: unset;
    //border-radius: 30px;
  }
`;

interface IExperienceAndEducation extends React.ComponentProps<'div'> {
  title?: string;
  style?: any;
  className: string;
}

const MeglerExperienceAndEducation: React.FC<IExperienceAndEducation> = ({
  style,
  children,
  className,
  title
}) => {
  return (
    <ExperienceAndEducationStyle className={className} style={style}>
      {title ? <Heading tag="h2">{title}</Heading> : null}
      {children}
    </ExperienceAndEducationStyle>
  );
};

export default MeglerExperienceAndEducation;
