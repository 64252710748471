import React from 'react';
import styled from 'styled-components';

interface IFlexRow {
  className?: string;
}
const FlexRow: React.FC<IFlexRow> = ({ children, className }) => {
  return <FlexRowStyle className={className}>{children}</FlexRowStyle>;
};

const FlexRowStyle = styled.div``;

export default FlexRow;
