import * as React from 'react';
import styled from 'styled-components';

import WhyPrivatMegleren from './WhyPrivatMegleren';
import MeglerQuote from './references/MeglerQuote';
import MeglerTeamAbout from './team/MeglerTeamAbout';
import MeglerContact from './MeglerContact';
import MeglerPartners from './other/MeglerPartners';
import MeglerTop from './MeglerTopSingle';
import FlexContainer from './flex/FlexContainer';
import FlexRow from './flex/FlexRow';
import { MeglerExperienceAndEducationWithContent } from './other/MeglerExperienceAndEducationWithContent';

import MeglerReferences from './references/MeglerReferences';
import MeglerAboutWrapper from './about/MeglerAboutWrapper';
import { Broker } from './Carousels/Broker';
import { MeglerImage } from './ui/MeglerImage';
import MeglerQuestionsAnswers from './other/MeglerQuestionsAnswers';
import { useMenuHide } from '../../../utils/hooks/useMenuHide';
import { PropertyList } from './Carousels/PropertyList';
import { Video } from './ui/Video';
import {
  Employee,
  McvFeedbackItem,
  McvProfileItem
} from '../../../__types__/generated/cluster';

interface IMeglerOverviewArgs {
  employee?: Partial<Employee>;
  profile?: Partial<McvProfileItem>;
  className?: string;
}

const Wrapper = styled.div``;

const MeglerOverview: React.FC<IMeglerOverviewArgs> = ({
  employee,
  profile,
  className
}) => {
  const hideMenu = useMenuHide();
  const [mainFeedback, SetMainFeedback] =
    React.useState<McvFeedbackItem | null>(null);
  const [brokerUrlTag, SetBrokerUrlTag] = React.useState<string | undefined>(
    employee?.employeeAlias
  );
  const [officeUrlTags, SetOfficeUrlTags] = React.useState<
    string[] | undefined
  >(undefined);

  React.useEffect(() => {
    SetBrokerUrlTag(employee?.employeeAlias);
    const tags: string[] =
      employee?.departments
        ?.filter((e) => e?.alias)
        ?.map((e) => {
          return e?.alias;
        }) ?? [];
    SetOfficeUrlTags(tags);
  }, [employee]);

  React.useEffect(() => {
    if (profile?._id) {
      const fb = profile?.feedback?.find((e) => {
        return e?.text;
      });
      SetMainFeedback(fb ?? null);
    } else {
      SetMainFeedback(null);
    }
  }, [profile?._id]);

  const getPath = (path?: string | null) => {
    if (path) {
      if (path?.startsWith('/') && path?.length > 1) return path?.substring(1);
      return path;
    }
    return '';
  };

  return (
    <MeglerOverviewStyle
      style={{ opacity: profile?._id && employee?.employeeAlias ? 1 : 0 }}
      className={hideMenu ? 'goToTop' : ''}
    >
      <MeglerTop employee={employee} backgroundImage={profile?.bkgImg?.url} />
      <FlexContainer className="" reverse>
        <FlexRow className="CV-right">
          <Broker
            className="broker"
            video={profile?.video}
            profileImage={profile?.profileImageUrl}
          />
          {profile?.video?.url && profile?.video?.type ? (
            <Video
              className="video video-desktop VimeoOuterContainer"
              type={profile?.video?.type}
              videoId={profile?.video?.videoId}
            />
          ) : profile?.profileImageUrl ? (
            <ImageBox className={className ? className : ''}>
              <img
                src="https://cdn.reeltime.no/pm_assets/meglercv/engodstart.jpg"
                alt="En god start"
                title="En god start"
              />
            </ImageBox>
          ) : null}

          {!profile?.exp ? null : (
            <>
              <MeglerExperienceAndEducationWithContent
                title="Erfaring og utdanning"
                className="experience-desktop"
                experience={profile.exp}
                useFiller={true}
              />
            </>
          )}
        </FlexRow>
        <FlexRow className="CV-left">
          <MeglerAboutWrapper
            profileTitle="Hvem er jeg?"
            profileItems={profile?.profile}
            leisureTitle="På fritiden"
            leisureItems={profile?.leisure}
            workTitle="På jobb"
            workItems={profile?.work}
          />
          {!profile?.quiz ? null : (
            <MeglerQuestionsAnswers
              style={{ textAlign: 'center' }}
              title="Q&A"
              quiz={profile.quiz}
            />
          )}
          {!profile?.exp ? null : (
            <MeglerExperienceAndEducationWithContent
              title="Erfaring og utdanning"
              className="experience-mobile"
              experience={profile.exp}
            />
          )}
        </FlexRow>
      </FlexContainer>
      {!mainFeedback?.text ? null : (
        <MeglerQuote
          style={{ textAlign: 'center' }}
          text={mainFeedback.text}
          author={undefined}
        />
      )}
      {brokerUrlTag?.length || officeUrlTags?.length ? (
        <FlexContainer className="meglerproperties">
          <FlexRow className="item">
            <PropertyList
              className="properties-left"
              sold={true}
              showTitle
              employeeTitle="Mine siste solgte"
              employeeDescription="Her er et utvalg av eiendommer jeg har solgt eller har vært med på å selge."
              employeeAlias={employee?.employeeAlias}
              departmentTitle="Kontorets siste solgte eiendommer"
              departmentDescription="Her er de siste eiendommene kontoret har solgt"
              departmentAliases={officeUrlTags}
            />
          </FlexRow>
          <FlexRow className="item">
            <PropertyList
              className="properties-right"
              sold={false}
              showTitle
              employeeTitle="Eiendommer jeg selger nå"
              employeeDescription="Dette er noen av de eiendommene jeg for tiden jobber med å omsette."
              employeeAlias={employee?.employeeAlias}
              departmentTitle="Eiendommer kontoret selger nå"
              departmentDescription="Her er de siste eiendommene kontoret har til salgs"
              departmentAliases={officeUrlTags}
            />
          </FlexRow>
        </FlexContainer>
      ) : null}
      <WhyPrivatMegleren />
      <FlexContainer className="bottom-section" reverse columnReverse>
        <FlexRow className="bottom-left team-mobile">
          <MeglerTeamAbout
            className=""
            tag={
              employee?.departments
                ?.find((e) => e?.tags && e?.tags?.length > 0)
                ?.tags?.find((e) => e) ?? null
            }
            departmentAlias={
              employee?.departments
                ?.filter((e) => e?.alias)
                ?.find((e) => {
                  return e?.alias;
                })?.alias
            }
          />
        </FlexRow>
        <FlexRow className="bottom-right">
          {
            /*           {profile?.video?.url && profile?.video?.type ? (
            <Video
              className="video"
              type={profile?.video?.type}
              videoId={profile?.video?.videoId}
            />
          ) : profile?.profileImageUrl ? (
            <MeglerImage className="megler" url={profile?.profileImageUrl} />
          ) : null} 
          */
            profile?.profileImageUrl ? (
              <>
                <MeglerImage
                  className="megler"
                  url={profile?.profileImageUrl}
                />
              </>
            ) : null
          }
        </FlexRow>
      </FlexContainer>
      <FlexContainer className="bottom-section-2" reverse columnReverse>
        <FlexRow className="bottom-left ">
          <MeglerPartners
            title="Mine samarbeidspartnere"
            items={profile?.myPartners}
          />
        </FlexRow>
        <FlexRow className="bottom-right">
          <MeglerContact
            title="Kontakt"
            name={employee?.name ?? undefined}
            cellphone={employee?.mobilePhone ?? undefined}
            email={employee?.email ?? undefined}
            path={employee?.employeeAlias ?? undefined}
            showMobile
            showDesktop
          />
        </FlexRow>
      </FlexContainer>
      <FlexContainer className="meglerReferences">
        <MeglerReferences
          items={profile?.feedback}
          showDesktopRef
          showMobileRef
        />
      </FlexContainer>
    </MeglerOverviewStyle>
  );
};

const ImageBox = styled.div`
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
`;

const MeglerOverviewStyle = styled.div`
  position: relative;
  padding-top: 5em;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.goToTop {
    padding-top: 0;
  }
  .broker {
    padding-bottom: 1em;
  }
  .top-carousel {
    margin-top: -3em;
  }
  .meglercvtitle {
    text-align: center;
  }

  .meglertext {
    text-align: center;
    padding: 0 2em;
  }

  .experience-desktop {
    display: none;
  }

  .video-desktop {
    display: block;
  }

  .VimeoOuterContainer {
    padding-bottom: 2em;
  }
  .team {
    padding-top: 2rem;
  }
  .teamPM {
    padding: 0 2rem;
  }
  .meglerproperties {
    margin-top: 2em;
    .item {
      width: 90%;
      margin: 0 auto;
    }
  }
  .btm-line {
    margin-top: 2em;
  }
  .bottom-section {
    margin-top: 0em;
    display: flex;
    flex-direction: column-reverse;

    .video {
      aspect-ratio: unset;
      height: 100%;
      background: black;
    }
  }
  .CV-right {
    width: 100%;
    /*     padding: 0 3em; */
  }

  .meglerReferences {
    background-color: black;
    margin-top: -2em;
    padding-bottom: 2em;
  }
  .bottom-left {
    width: 100%;
  }
  .team-mobile {
    /*  padding: 0 2em; */
  }
  .bottom-right {
    width: 100%;
  }
  .CV-right {
    width: 100%;
    /*  padding: 0 1em; */
  }

  @media all and (min-width: 900px) {
    .top-carousel {
      margin-top: -4em;
    }

    .imagebroker {
      display: flex;
    }

    .CV-left {
      width: 100%;
      max-width: 1500px;
      margin-top: 4em;
    }
    .meglertext {
      width: 100%;
      margin: 0 auto;
      max-width: 80%;
    }
    .bottom-section {
      background-color: #171719;
      padding: 3em 0 2em 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
    }
  }
  @media all and (min-width: 1100px) {
    .experience-desktop {
      display: block;
      /*       margin-top: 3em; */
    }
    .video-desktop {
      display: block;
    }
    .CV-right {
      width: 100%;
      padding: 0 3em;
    }
    .top {
      width: 100%;
    }
    .experience-mobile {
      display: none;
    }
  }

  /* Tweaking */
  /*   @media all and (min-width: 1000px) */
  @media all and (max-width: 1000px) {
    .bottom-section {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .video {
        aspect-ratio: 16 / 9;
        padding: 0 2em;
        background: none;
      }
    }
    .bottom-section-2 {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  /* Tweaking */
  /*   @media all and (min-width: 1000px) */
  @media all and (min-width: 1000px) {
    .meglerproperties {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 3em 0em;
    }
    .bottom-section {
      margin-top: 0em;
      padding: 0 3em;
      column-gap: 2em;
    }
    .bottom-section-2 {
      background-color: #2e2d2e;
      padding: 3em;
      margin-top: -2em;
    }
  }

  @media all and (min-width: 1400px) {
    .meglerproperties {
      padding: 3em 3em;
      .item {
        max-width: 600px;
        margin: 0 auto 0 0;
        :first-child {
          margin: 0 0 0 auto;
        }
      }
    }
  }
`;

export default MeglerOverview;
