import * as React from 'react';
import styled, { useTheme } from 'styled-components';

interface IEmailIcon {
  color?: string;
  className?: string;
  style?: any;
}

export const EmailIcon: React.FC<IEmailIcon> = ({
  color = 'black',
  className
}) => {
  return (
    <StyledIcon className={className}>
      <svg
        width="27"
        height="21"
        viewBox="0 0 27 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4001 10.0385L24.5183 2.40926V17.6677L17.4001 10.0385ZM9.91041 11.2431L12.5516 13.7539C12.8573 14.0446 13.3507 14.0437 13.6553 13.7517L16.2305 11.2823L23.7274 18.4709H2.37274L9.91041 11.2431ZM13.096 12.0462L2.37274 1.60614H23.7274L13.096 12.0462ZM1.58183 2.40926L8.70003 10.0057L1.58183 17.6677V2.40926ZM26.1001 2.31656C26.1001 1.04399 25.074 0 23.8061 0H2.29395C1.03065 0 0 1.03967 0 2.31656V17.7604C0 19.033 1.02606 20.077 2.29395 20.077H23.8061C25.0663 20.077 26.1001 19.0408 26.1001 17.7604V2.31656Z"
          fill={color}
        />
      </svg>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  svg {
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const EmailOldIcon: React.FC<IEmailIcon> = ({
  color = 'black',
  className,
  style
}) => {
  const theme = useTheme();
  return (
    <StyledOldIcon className={className} style={style}>
      <svg
        width="30"
        height="19"
        viewBox="0 0 30 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="19" rx="2" fill={theme.colors.primary} />
        <path
          d="M3 3L10.6364 10M27 3L19.3636 10M19.3636 10L16.3514 12.7612C15.7098 13.3493 15.389 13.6434 15 13.6434C14.611 13.6434 14.2902 13.3493 13.6486 12.7612L10.6364 10M19.3636 10L27 17M10.6364 10L3 17"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledOldIcon>
  );
};

const StyledOldIcon = styled.div``;
