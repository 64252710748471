import { useQuery } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { client } from '../../../../__graphql__/clientCluster';
import { Logo } from '../../../ui/logo/office/Index';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { MeglerTeamItem } from './MeglerTeamItem';
import { MEGLERCV_FRONTEND_QUERY } from '../../../../../client/__graphql__/cluster/queries/profile/meglerCvFrontend';
import {
  McvFrontendQuery,
  McvFrontendQueryVariables
} from '../../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../../__config__/env';

interface IMeglerTeamAbout {
  className: string;
  departmentAlias?: string | null;
  tag?: string | null;
}

const MeglerTeamAbout: React.FC<IMeglerTeamAbout> = ({
  className,
  departmentAlias,
  tag
}) => {
  const { data, loading, error } = useQuery<
    McvFrontendQuery,
    McvFrontendQueryVariables
  >(MEGLERCV_FRONTEND_QUERY, {
    fetchPolicy: 'cache-and-network',
    client: client,
    skip: !departmentAlias && !tag,
    variables: {
      input: {
        brandId: ENV_CONFIG?.BRAND_ID,
        fields: ['team'],
        officeTag: departmentAlias ?? undefined,
        tag: tag ?? undefined
      }
    }
  });

  return (
    <>
      <MeglerTeamAboutStyle className={className}>
        {data?.mcvFrontend?.fields?.length ? (
          data?.mcvFrontend?.fields?.map((obj, index) => {
            if (!obj) return null;
            return (
              <MeglerTeamItem
                key={`team${index}`}
                title={obj?.title}
                content={obj?.content}
              />
            );
          })
        ) : (
          <>
            <Heading
              className="meglertitle"
              style={{ textAlign: 'center' }}
              tag="h2"
            >
              Sjeldne anledninger er vår hverdag
            </Heading>
            <Paragraph className="meglertekst" center>
              I et hjem skal man føle tilhørighet. Hjemmet ditt er en viktig del
              av deg og hverdagen du lever i. Vi ønsker å bidra til at mennesker
              skal finne et hjem de føler tilhørighet til og elsker. Gjennom
              integritet, lidenskap, skreddersøm og vinnervilje skal vi alltid
              etterstrebe de beste løsningene for å kjøpe eller selge en bolig.
              Vi vet hvor viktig dette er, og vi vet at det vi gjør hver eneste
              dag er en sjelden anledning for våre kunder. Derfor består vårt
              fagmiljø av svært kompetente eiendomsmeglere, med et vennlig
              konkurranseinstinkt.
            </Paragraph>
            <Paragraph className="meglertekst" center>
              Som en viktig del av oss har vi smarte verktøy som styrker vår
              leveranse, og ikke minst markedsføringen av det vi omsetter. Med
              tjenesten Kvadrat får du blant annet full oversikt over
              interessenter til enhver tid, og du kan følge direkte med på hvor
              mange som kommer på visning - og ikke minst kan du følge budrunden
              direkte.
            </Paragraph>
          </>
        )}
        <Logo style={{ margin: '0 auto' }} name="" alt="bilde av logo" />
      </MeglerTeamAboutStyle>
    </>
  );
};

const MeglerTeamAboutStyle = styled.div`
  position: relative;
  background-color: black;
  padding: 5em 1.5em;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .meglercvtitle {
    padding-top: 2em;
    margin: 0;
  }
  .meglertekst {
    width: 100%;
    margin: 0 auto;
    max-width: 80%;
    padding: 1em 0 2em;
  }

  @media all and (min-width: 1100px) {
    .meglertitle {
      margin: 0;
    }
    .meglertekst {
      width: 100%;
      margin: 0 auto;
      max-width: 80%;
    }
  }
`;

export default MeglerTeamAbout;
