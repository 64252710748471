import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import FlexRow from '../flex/FlexRow';
import { RainbowLine } from '../ui/RainbowLine';
import { MeglerAboutItem } from './MeglerAboutItem';
import { McvProfileText } from '../../../../__types__/generated/cluster';

interface IMeglerAbout {
  profileTitle?: string;
  profileItems?: Partial<McvProfileText>[] | null;
  workTitle?: string;
  workItems?: Partial<McvProfileText>[] | null;
  leisureTitle?: string;
  leisureItems?: Partial<McvProfileText>[] | null;
}

export const MeglerAboutWrapper: React.FC<IMeglerAbout> = ({
  profileTitle,
  workTitle,
  leisureTitle,
  profileItems,
  workItems,
  leisureItems
}) => {
  return (
    <MeglerAboutStyle>
      <FlexRow className="top-left">
        {!profileTitle ? null : (
          <>
            <RainbowLine className="line" />
            <Heading className="meglercvtitle" tag="h2">
              {profileTitle}
            </Heading>
            <RainbowLine className="line" />
            <MeglerAboutItem
              className="padding"
              showItemTitle={true}
              items={profileItems}
            />
          </>
        )}
        <MeglerAboutItem title={workTitle} items={workItems} />
        <MeglerAboutItem title={leisureTitle} items={leisureItems} />
      </FlexRow>
    </MeglerAboutStyle>
  );
};

export default MeglerAboutWrapper;

const MeglerAboutStyle = styled.div`
  position: relative;
  .top-left {
    background-color: #171719;
  }
  .meglercvtitle {
    text-align: center;
  }

  /*.meglertext {
    text-align: center;
    padding: 0 2em;
  }*/
  .line {
    width: 63%;
  }

  /* Desktop */
  @media all and (min-width: 600px) {
    .line {
      width: 42%;
    }
  }
  /* Desktop */
  @media all and (min-width: 900px) {
    .top-container {
      position: absolute;
      bottom: 0;
    }
  }
`;
