export const formatPhone = (value?: string | null) => {
  if (!value?.length) return '';
  try {
    const number = value.replace(/\D/g, '');
    const format = number.match(/^(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (!(format && format?.length > 3)) return value;
    return format?.filter((e, index) => index > 0)?.join(' ');
  } catch {}
  return value;
};
