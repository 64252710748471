import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { McvProfileText } from '../../../../__types__/generated/cluster';

interface IMeglerPartners {
  title: string;
  items?: Partial<McvProfileText>[] | null;
}
const MeglerPartners: React.FC<IMeglerPartners> = ({ title, items }) => {
  if (!items?.length) {
    return null;
  }
  const getHtml = (value: string) => {
    if (!value) return value;
    return value?.replace(/\n/g, '<br/>');
  };
  return (
    <MeglerPartnersStyle>
      <Heading className="meglerTopTitle" tag="h2">
        {title}
      </Heading>
      {items?.map((item, index: number) => {
        return (
          <Card key={`partner${index}`}>
            <Heading className="meglerPartnerTitle" tag="h3">
              {item?.title}
            </Heading>
            {item?.text ? (
              <p
                className="meglerPartnerText"
                dangerouslySetInnerHTML={{ __html: getHtml(item?.text) }}
              ></p>
            ) : null}
          </Card>
        );
      })}
    </MeglerPartnersStyle>
  );
};

const Card = styled.div``;

const MeglerPartnersStyle = styled.div`
  text-align: center;
  background-color: black;
  padding: 3em 0 1em 0;
  margin-top: -1em;
  .meglerTopTitle {
  }
  .meglerPartnerText {
    padding: 0 2em;
    margin-top: 0;
  }
  .meglerPartnerTitle {
    font-size: 30px;
    margin: 0;
  }
  /* Desktop */
  @media all and (min-width: 900px) {
    background-color: #2e2d2e;

    .meglerTopTitle {
      margin: 0;
    }
    .meglerPartnerTitle {
      font-size: 30px;
      margin: 0;
    }
    .meglerPartnerText {
      margin-top: 0;
      padding: 0;
      font-style: italic;
    }
  }
`;

export default MeglerPartners;
