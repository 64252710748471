import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EmailOldIcon } from '../../ui/icons/Email';
//import Phone from '../../ui/icons/Phone';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { SharingVcard } from '../broker/ui/SharingVcard';
import { formatPhone } from '../../../utils/format/formatPhone';

const MeglerContactStyleMobile = styled.div`
  //display: block;
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 1em;
  background-color: #2e2d2e;
  padding: 0 4em 3em;
  margin-top: 5em;

  p {
    margin: 0;
  }
  .titlemobile {
    margin-bottom: 0.5rem;
  }
  .meglercvtext {
    color: white;
    padding: 0 2em;
    margin: 0;
  }
  span {
    color: white;
  }
  .emailicon {
    padding-top: 10px;
  }
  #email {
    text-decoration: underline;
    word-break: break-word;
  }
  .phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon {
      padding: 0 0 0 6px;
      margin: 0;
      height: 16px;
    }
  }
  @media all and (min-width: 900px) {
    display: none;
  }
`;

const MeglerContactStyleDesktop = styled.div`
  display: none;

  @media all and (min-width: 900px) {
    display: block;
    text-align: center;
    background-color: #2e2d2e;
    //padding-top: 2.3em;
    padding-top: 0;
    .meglercvtext {
      color: white;
      &.trim {
        margin-bottom: 0;
      }
    }
    span {
      color: white;
    }
    .titledesktop {
      margin-top: 0;
    }
    #email {
      text-decoration: underline;
      word-break: 'break-word';
    }
  }
`;

const Redirect = styled.div`
  .v-card {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

interface IMeglerContact {
  showMobile?: boolean;
  showDesktop?: boolean;
  name?: string;
  title?: string;
  cellphone?: string;
  email?: string;
  path?: string;
}

const MeglerContact: React.FC<IMeglerContact> = ({
  showMobile,
  showDesktop,
  name,
  title,
  cellphone,
  email,
  path
}) => {
  return (
    <>
      {showMobile ? (
        <MeglerContactStyleMobile>
          <Heading className="titlemobile" tag="h2">
            {title}
          </Heading>
          <Paragraph className="meglercvtext" center>
            Kontakt meg gjerne på telefon
          </Paragraph>
          <a href={`tel:${cellphone}`}>{cellphone ?? ''}</a>
          <Paragraph className="meglercvtext" center>
            eller epost
          </Paragraph>
          <div>
            <a id="email" href={`mailto:${email}`}>
              {email}
            </a>
            <OfficeEmail>
              <a href={`mailto:${email}`}>
                <EmailOldIcon className="emailicon" />
              </a>
            </OfficeEmail>
            {name && path ? (
              <Redirect>
                <SharingVcard
                  className="v-card"
                  name={name}
                  title={title ? title : ''}
                  path={path}
                  showTitles={true}
                  showCards={['download']}
                />
              </Redirect>
            ) : null}
          </div>
        </MeglerContactStyleMobile>
      ) : null}
      {showDesktop ? (
        <MeglerContactStyleDesktop>
          <Heading className="titledesktop" tag="h2">
            Kontakt
          </Heading>
          <Paragraph className="meglercvtext trim" center>
            Kontakt meg gjerne på telefon{' '}
            <a href={`tel:${cellphone}`}>{cellphone ?? ''}</a> eller epost{' '}
          </Paragraph>
          <Wrapper>
            <a id="email" href={`mailto:${email}`}>
              {email}
            </a>
            <Link to={''}>
              <EmailOldIcon />
            </Link>
          </Wrapper>
          {name && path ? (
            <Redirect>
              <SharingVcard
                className="v-card"
                name={name}
                title={title ? title : ''}
                path={path}
                showTitles={true}
                showCards={['download']}
              />
            </Redirect>
          ) : null}
        </MeglerContactStyleDesktop>
      ) : null}
    </>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  #email {
    margin-right: 9px;
    text-decoration: underline;
  }
`;
const OfficeEmail = styled.span`
  margin-left: 0;
`;

export default MeglerContact;
