import * as React from 'react';
import styled from 'styled-components';

const EntryStyle = styled.div`
  text-align: center;
  .year  {
    color: ${({ theme }) => theme.colors.primary};
  }

  .workTitle {
    font-weight: bold;
    color: white;
  }

  .employee {
    font-style: italic;
    padding: 0 2em;
  }
  /* Desktop */
  @media all and (min-width: 600px) {
  }
`;

interface IEntry {
  year: string;
  location: string;
  description?: string;
  style?: any;
}
const MeglerJob: React.FC<IEntry> = ({
  year,
  location,
  description,
  style
}) => {
  return (
    <EntryStyle style={style}>
      <div>
        <p className="year">
          {year} <span className="workTitle">{location}</span>
        </p>
        <p className="employee">{description}</p>
      </div>
    </EntryStyle>
  );
};

export default MeglerJob;
