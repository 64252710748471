import * as React from 'react';
import styled from 'styled-components';
import { MeglerReferenceItem } from './MeglerReferenceItem';
import { Pagination } from '../../../ui/pagination/Pagination';
import { McvFeedbackItem } from '../../../../__types__/generated/cluster';

const MeglerReferencesDesktopStyle = styled.div`
  padding: 0 3rem;

  .referencestitle {
    text-align: center;
    padding-top: 1em;
  }

  /* Desktop */
  @media all and (min-width: 700px) {
    width: 100%;
    max-width: 1500px;
    .referencestitle {
      text-align: center;
      padding-top: 1em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media all and (min-width: 700px) {
    .meglerreference {
      padding: 0 3rem;
    }
  }

  @media all and (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

interface IMeglerReferencesList {
  style?: any;
  className?: string;
  items: Partial<McvFeedbackItem>[];
  itemsPrPage: number;
}

export const MeglerReferencesList: React.FC<IMeglerReferencesList> = ({
  style,
  className,
  items,
  itemsPrPage
}) => {
  const [currentPage, SetCurrentPage] = React.useState(0);
  const [currentItems, SetCurrentItems] = React.useState<
    Partial<McvFeedbackItem>[]
  >([]);

  const onPrintItems = (page: number) => {
    const array: Partial<McvFeedbackItem>[] = [];
    const currentIndex = page * itemsPrPage;
    if (items?.length > 0 && items?.length > currentIndex) {
      if (items.length > currentIndex + itemsPrPage) {
        array.push(...items.slice(currentIndex, currentIndex + itemsPrPage));
      } else {
        array.push(...items.slice(currentIndex));
      }
    }
    SetCurrentItems(array);
  };

  React.useEffect(() => {
    onPrintItems(currentPage);
  }, [currentPage]);

  if (!(items?.length > 0 && itemsPrPage > 0)) {
    return null;
  }

  return (
    <MeglerReferencesDesktopStyle style={style} className={className}>
      {!currentItems?.length ? null : (
        <Grid>
          {currentItems?.map((obj, index: number) => {
            const { text } = obj;
            if (!text) return null;
            return (
              <MeglerReferenceItem
                key={`fb${index}`}
                className="meglerreference"
                text={text}
                person={undefined}
              />
            );
          })}
        </Grid>
      )}
      <Pagination
        center={true}
        currentPage={currentPage}
        maxPages={2}
        total={items?.length}
        pageRangeDisplayed={1}
        itemsPerPage={itemsPrPage}
        pageOnChange={({ selected }) => {
          SetCurrentPage(selected);
        }}
      />
    </MeglerReferencesDesktopStyle>
  );
};
