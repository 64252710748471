import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { McvQuizAnswer } from '../../../../__types__/generated/cluster';

const QuestionsAnswersStyle = styled.div`
  background-color: #171719;
  .QAndATitle {
    color: ${({ theme }) => theme.colors.primary};
  }
  .subtitle {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0;
    font-size: 1.5em;
  }
  .text {
    color: white;
    padding: 0 2em;
  }

  /* Desktop */
  @media all and (min-width: 600px) {
    .QAndATitle {
      margin-bottom: 0;
    }
    .white {
      margin-bottom: 0;
    }
  }

  @media all and (min-width: 600px) {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

interface IQuestionsAnswersArgs {
  style?: any;
  title?: string;
  quiz?: Partial<McvQuizAnswer>[];
}
const MeglerQuestionsAnswers: React.FC<IQuestionsAnswersArgs> = ({
  title,
  style,
  quiz
}) => {
  if (!quiz?.length) {
    return null;
  }
  return (
    <QuestionsAnswersStyle style={style}>
      <Heading className="QAndATitle" tag="h2">
        {title}
      </Heading>
      {quiz
        ?.filter((obj) => {
          return obj.answer && obj.question;
        })
        ?.map((obj, index: number) => {
          return (
            <div key={`quiz${index}`}>
              <Heading className="subtitle" tag="h3">
                {obj.question}
              </Heading>
              <Paragraph className="text" center>
                {obj.answer}
              </Paragraph>
            </div>
          );
        })}
    </QuestionsAnswersStyle>
  );
};

export default MeglerQuestionsAnswers;
