import { gql } from '@apollo/client';

export const MEGLERCV_FRONTEND_QUERY = gql`
  query mcvFrontend($input: McvFrontendInput!) {
    mcvFrontend(input: $input) {
      count
      fields {
        name
        title
        content {
          pos
          title
          value
        }
      }
    }
  }
`;
