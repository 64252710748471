import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { animated, useSpring } from 'react-spring';
import { Pagination } from '../../../ui/pagination/Pagination';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { SEARCH_ESTATE } from '../../../../../client/__graphql__/cluster/queries/search/estate';
import {
  Estate,
  SearchEstateQuery,
  SearchEstateQueryVariables,
  SearchEstateSort
} from '../../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../../__config__/env';
import { PropertyCard } from './PropertyCard';
import { getDefaultAssignmentTypes } from '../../search/util/filter/getDefaultAssignmentTypes';

enum ViewItemsFrom {
  Broker,
  Office
}

interface IMeglerCarousel {
  style?: any;
  className?: string;
  showTitle?: Boolean;
  sold: boolean;
  employeeTitle?: string;
  employeeDescription?: string;
  employeeAlias?: string;
  departmentTitle?: string;
  departmentDescription?: string;
  departmentAliases?: string[];
}

export const PropertyList: React.FC<IMeglerCarousel> = React.memo(
  ({
    sold,
    className,
    showTitle,
    employeeDescription,
    employeeAlias,
    employeeTitle,
    departmentDescription,
    departmentAliases,
    departmentTitle
  }) => {
    const [currentPage, SetCurrentPage] = React.useState(0);
    const [props, SetProp] = useSpring(() => ({ opacity: 0 }));
    const [itemsPerPage] = React.useState(1);
    const [totalItems, SetTotalItems] = React.useState(0);
    const [title, SetTitle] = React.useState(employeeTitle);
    const [description, SetDescription] = React.useState(employeeDescription);
    const [viewData, SetViewData] = React.useState(
      employeeAlias ? ViewItemsFrom.Broker : ViewItemsFrom.Office
    );
    const [items, SetProperties] = React.useState<Partial<Estate>[]>([]);
    const [search, { data, loading }] = useLazyQuery<
      SearchEstateQuery,
      SearchEstateQueryVariables
    >(SEARCH_ESTATE, {
      fetchPolicy: 'no-cache',
      onError: () => {
        if (viewData === ViewItemsFrom.Broker) {
          SetViewData(ViewItemsFrom.Office);
        }
      },
      onCompleted: ({ search }) => {
        if (search?.estate?.total && search?.estate?.list?.length) {
          SetProperties(search?.estate?.list);
          SetTotalItems(search?.estate?.total);
        } else if (viewData === ViewItemsFrom.Broker) {
          SetViewData(ViewItemsFrom.Office);
        }
      }
    });

    useEffect(() => {
      if (viewData === ViewItemsFrom.Broker) {
        SetTitle(employeeTitle);
        SetDescription(employeeDescription);
      } else {
        SetTitle(departmentTitle);
        SetDescription(departmentDescription);
      }
      SearchData(currentPage);
    }, [viewData]);

    const SearchData = (page: number) => {
      if (viewData === ViewItemsFrom.Broker && employeeAlias) {
        search({
          variables: {
            finn: true,
            input: { brandId: ENV_CONFIG?.BRAND_ID },
            filter: {
              sold: sold,
              isProjectUnit: false,
              published: true,
              assignmentTypes: getDefaultAssignmentTypes(),
              employeeAliases: [employeeAlias],
              sort: sold
                ? SearchEstateSort.RecentlySold
                : SearchEstateSort.PublishedNewOld,
              pagination: { limit: itemsPerPage, page: page }
            }
          }
        });
      } else if (departmentAliases) {
        search({
          variables: {
            finn: true,
            input: { brandId: ENV_CONFIG?.BRAND_ID },
            filter: {
              sold: sold,
              isProjectUnit: false,
              published: true,
              assignmentTypes: getDefaultAssignmentTypes(),
              departmentAliases: departmentAliases,
              sort: sold
                ? SearchEstateSort.RecentlySold
                : SearchEstateSort.PublishedNewOld,
              pagination: { limit: itemsPerPage, page: page }
            }
          }
        });
      }
    };

    const onPageChange = (page: number) => {
      SetCurrentPage(page);
      SearchData(page);
    };

    React.useEffect(() => {
      SetProp({ opacity: 0, immediate: true });
      SetProp({ delay: 1000, opacity: 1 });
    }, [currentPage]);

    React.useEffect(() => {
      SetProp({ opacity: 1 });
    }, []);

    if (!totalItems) {
      return null;
    }

    return (
      <StyledCarouselWrapper className={`grid ${className}`}>
        <CarouselSection className="flex">
          {showTitle ? (
            <>
              <Heading className="carousel-title" tag="h2">
                {title}
              </Heading>
              {description ? (
                <Paragraph className="carousel-description" center>
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                </Paragraph>
              ) : null}
            </>
          ) : null}
          <Container style={props}>
            {items?.length
              ? items?.map((obj, index: number) => {
                  return (
                    <PropertyCard
                      key={obj?.estateId}
                      item={obj}
                      currentPage={1}
                    />
                  );
                })
              : null}
          </Container>
        </CarouselSection>

        <Pagination
          center={true}
          currentPage={currentPage}
          maxPages={2}
          total={totalItems}
          pageRangeDisplayed={1}
          itemsPerPage={1}
          pageOnChange={({ selected }) => {
            onPageChange(selected);
          }}
        />
      </StyledCarouselWrapper>
    );
  }
);

const Container = styled.div`
  .property-item {
    height: unset;
  }
`;

const StyledCarouselWrapper = styled.div`
  display: block;
  width: 100%;

  /* Desktop */
  @media all and (min-width: 700px) {
    padding: unset;
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    @media all and (min-width: 1000px) {
      grid-template-rows: 1fr 4.5rem;
    }
  }
`;

const CarouselSection = styled(animated.div)`
  display: block;
  text-align: center;
  padding: 4em 1rem 0 1rem;
  .carousel-description {
    padding: 0 30px;
  }
  .carousel-title {
    margin: 0;
  }
  /* Desktop */
  @media all and (min-width: 600px) {
    .carousel-title {
      padding-top: unset;
    }
    .carousel-description {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
