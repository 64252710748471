import * as React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Icon } from '../ui/Icon';
import { McvProfileText } from '../../../../__types__/generated/cluster';

interface IArgs {
  title?: string;
  showItemTitle?: boolean;
  items?: Partial<McvProfileText>[] | null;
  className?: string;
}

export const MeglerAboutItem: React.FC<IArgs> = ({
  title,
  className,
  showItemTitle,
  items
}) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {!title ? null : (
        <Heading className="meglercvtitle" tag="h2">
          <div dangerouslySetInnerHTML={{ __html: title }} />
          <Icon className="icon" />
        </Heading>
      )}
      {items?.map((e, index: number) => {
        if (e?.text) {
          if (showItemTitle && e?.title) {
            return (
              <WrapItem key={`t${index}`}>
                <Heading className="subtitle" tag="h3">
                  {e?.title}
                </Heading>
                <TextStyle>{e.text}</TextStyle>
              </WrapItem>
            );
          }
          return <TextStyle key={`t${index}`}>{e.text}</TextStyle>;
        }
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  &.padding {
    margin-top: 25px;
  }
  .meglercvtitle {
    .icon {
      margin-top: 0.3em;
      display: flex;
      justify-content: center;
    }
    margin-bottom: 0.4em;
  }
`;

const WrapItem = styled.div`
  text-align: center;
  .subtitle {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0;
    font-size: 1.5em;
  }
  p {
    margin: 1em auto;
  }
`;

const TextStyle = styled(Paragraph)`
  text-align: center;
  padding: 0 2em;

  /* Desktop */
  @media all and (min-width: 900px) {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
  }
`;
