import * as React from 'react';
import styled from 'styled-components';

interface IUnits {
  className?: string;
  sold: number;
  available: number;
  total: number;
  teaser?: boolean;
}

const SubUnits: React.FC<IUnits> = ({
  className,
  total,
  sold,
  available,
  teaser
}) => {
  let str = '';
  if (teaser) {
    str = 'Kommer for salg';
  } else {
    // TODO ADD WHEN FIX OF COUNT IS APPLIED
    /*
    if (total > 0 && sold === total) {
      str = 'Utsolgt';
    } else {
      if (sold > total / 2) {
        str = `${sold} av ${total} solgt`;
      } else {
        str = `${available} av ${total} ledige`;
      }
    }*/
  }
  if (!str?.length) return null;
  return <StyledPropertyUnits className={className}>{str}</StyledPropertyUnits>;
};

export default SubUnits;

const StyledPropertyUnits = styled.div`
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 1em;
  color: #fff;
  left: 0;
  padding: 0.5em 1em;
  position: absolute;
  text-align: center;
  width: 100%;
`;
