import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Pagination } from '../../../ui/pagination/Pagination';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { BrokerItem, IItem, IType } from './BrokerItem';
import { McvVideoItem } from '../../../../__types__/generated/cluster';

interface IMeglerCarousel {
  style?: any;
  title?: string;
  description?: string;
  className?: string;
  video?: Partial<McvVideoItem> | null;
  profileImage?: string | null;
}

export const Broker: React.FC<IMeglerCarousel> = React.memo(
  ({ title, description, className, video, profileImage }) => {
    const [page, SetPage] = React.useState(0);
    const [current, SetCurrent] = React.useState<IItem>();
    const [items, SetItems] = React.useState<IItem[]>([]);
    const [props, set] = useSpring(() => ({ opacity: 0 }));

    React.useEffect(() => {
      set({ opacity: 0, immediate: true });
      SetCurrent(items[page]);
      set({ delay: 100, opacity: 1 });
    }, [page]);

    React.useEffect(() => {
      const array: IItem[] = [];
      if (profileImage) {
        array.push({ type: IType.Image, url: profileImage });
      }
      //if (video && video?.type && video?.videoId) {
      //  array.push({ type: video?.type as IType, url: video?.videoId });
      //}
      SetItems(array);
      if (array?.length > 0) {
        SetCurrent(array[0]);
      }
      set({ opacity: 1 });
    }, [video, profileImage]);

    if (!items?.length) return null;

    return (
      <BrokerStyledCarouselWrapper className={className}>
        <BrokerCarouselSection>
          {title ? (
            <Heading className="broker-carousel-title" tag="h2">
              {title}
            </Heading>
          ) : null}
          <Paragraph className="broker-carousel-description" center>
            {description}
          </Paragraph>
          {current ? (
            <div style={props}>
              <BrokerItem
                type={current?.type}
                url={current?.url}
                text={current?.text}
              />
            </div>
          ) : null}
        </BrokerCarouselSection>
        <Pagination
          center={true}
          currentPage={page}
          maxPages={2}
          total={items.length}
          pageRangeDisplayed={1}
          itemsPerPage={1}
          pageOnChange={({ selected }) => {
            SetPage(selected);
          }}
        />
      </BrokerStyledCarouselWrapper>
    );
  }
);

const BrokerStyledCarouselWrapper = styled.div`
  display: block;
  /*   padding: 0 1rem 0 1rem; */
  margin-top: -2em;
  /* Desktop */
  @media all and (min-width: 700px) {
    padding: unset;
  }
`;

const BrokerCarouselSection = styled(animated.div)`
  display: block;
  text-align: center;
  .broker-carousel-description {
    padding: 0 30px;
  }
  .broker-carousel-title {
    margin: 0;
  }
  /* Desktop */
  @media all and (min-width: 600px) {
    .carousel-title {
      padding-top: unset;
    }
  }
`;

const BrokerCarouselImageOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .broker-carousel-overlay-text {
    font-family: 'DomaineDisp', sans-serif;
    font-weight: 300;
    font-size: 18px;
    display: inline-block;
    padding: 5px;
    color: ${({ theme }) => theme.colors.primary};

    .red {
      color: #ff0021;
    }
    /* Desktop */
    @media all and (max-width: 600px) {
      width: 90%;
    }
  }
`;

const BrokerCarouselImageWrapper = styled.div`
  position: relative;
  display: block;
  .brokerimages {
    width: 100%;
  }
  /* Desktop */
  @media all and (max-width: 700px) {
    .brokerimages {
      width: 100%;
      max-width: 600px;
    }
  }
`;
