import React from 'react';
import styled from 'styled-components';

const FlexContainerStyle = styled.div<IFlexContainer>`
  display: block;
  /*   padding-top: 3em; */
  /*   ${(props) =>
    props.columnReverse ? 'flex-direction: column-reverse;' : ''} */
  @media all and (min-width: 1100px) {
    display: flex;
    justify-content: center;
    ${(props) => (props.reverse ? 'flex-direction: row-reverse;' : '')}
  }
`;

interface IFlexContainer {
  reverse?: boolean;
  columnReverse?: boolean;
  className?: string;
}
const FlexContainer: React.FC<IFlexContainer> = (props) => {
  return (
    <FlexContainerStyle
      className={props.className}
      reverse={props.reverse}
      /*    columnReverse={props.columnReverse} */
    >
      {props.children}
    </FlexContainerStyle>
  );
};

export default FlexContainer;
