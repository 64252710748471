import * as React from 'react';
import styled from 'styled-components';
import { Prices } from '../../../../../__types__/generated/cluster';
import { currency } from '../../../../../utils/format/currency';

interface IPrices {
  prices?: Partial<Prices>;
}

const PriceInfo: React.FC<IPrices> = ({ prices }) => {
  if (!prices) return <>-</>;
  const { askingPrice, askingPriceTo } = prices;
  if (!askingPrice) return <>-</>;
  if (askingPriceTo && askingPrice != askingPriceTo) {
    return (
      <>
        {currency({
          number: askingPrice
        })}{' '}
        - {currency({ number: askingPriceTo })},-
      </>
    );
  }
  return <>{currency({ number: askingPrice })},-</>;
};

export default PriceInfo;
